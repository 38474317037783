import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, mergeMap } from 'rxjs/operators';

/* actions */
import * as groupPolicyActions from './group-policy-data-store.actions';
import { GroupPolicyApiService } from '@app/core/services/api/group-policy-api.service';
import { forkJoin } from 'rxjs';

@Injectable()
export class GroupPolicyDataStoreEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private groupPolicyApiService: GroupPolicyApiService
  ) { }

  getGroupPolicyList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groupPolicyActions.getGroupPolicyList),
      mergeMap(action => {
        return this.groupPolicyApiService.getGroupPolicyList(action.payload.params).pipe(
          mergeMap((res: any) => {
            return [groupPolicyActions.getGroupPolicyListSuccessfully({
              payload: {
                groupPolicyList: res?.body || [],
                groupPolicyCount: +res.headers.get('x-total-count')
              }
            })];
          })
        );
      })
    )
  );

  getGroupPolicyById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groupPolicyActions.getGroupPolicyById),
      mergeMap(action => {
        return this.groupPolicyApiService.getGroupPolicyById(action.payload.groupId).pipe(
          mergeMap((res: any) => {
            return [groupPolicyActions.getGroupPolicyByIdSuccessfully({
              payload: {
                groupPolicy: res?.body
              }
            })];
          })
        );
      })
    )
  );

  createGroupPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groupPolicyActions.createGroupPolicy),
      mergeMap(action => {
        return this.groupPolicyApiService.createGroupPolicy(action.payload).pipe(
          mergeMap((res: any) => {
            return [groupPolicyActions.createGroupPolicySuccessfully()];
          })
        );
      })
    )
  );

  updateGroupPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groupPolicyActions.updateGroupPolicy),
      mergeMap(action => {

        const queryList = [];
        if (action.payload.hasOwnProperty('name') || action.payload.hasOwnProperty('description')) {
          const patchParams = {
            name: action.payload.name,
            description: action.payload.description,
            jobType: action.payload.jobType
          };
          queryList.push(this.groupPolicyApiService.patchGroupPolicyTemplate(action.payload.templateId, patchParams));
        }

        if (action.payload.hasOwnProperty('policy')) {
          queryList.push(this.groupPolicyApiService.putGroupPolicyTemplate(action.payload.templateId, { policy: action.payload.policy }));
        }

        return forkJoin(queryList).pipe(
          mergeMap((res: any) => {
            return [groupPolicyActions.updateGroupPolicySuccessfully()];
          })
        );
      })
    )
  );

}
