import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ApplicationState } from "./application-data-store.interfaces";
import { featureKey } from "./application-data-store.reducer";

export const selectApplicationState = createFeatureSelector<ApplicationState>(featureKey);


export const selectApplication = createSelector(selectApplicationState, state => {
  return state?.application;
});

export const selectRole = createSelector(selectApplicationState, state => {
  return state?.role;
});

export const selectApplicationList = createSelector(selectApplicationState, state => {
  return state?.applicationList;
});

export const selectAllApplications = createSelector(selectApplicationState, state => {
  return state?.allApplications;
});

export const selectApplicationListCount = createSelector(selectApplicationState, state => {
  return state?.applicationCount;
});

export const selectRoleList = createSelector(selectApplicationState, state => {
  return state?.roles;
});

export const selectRoleListCount = createSelector(selectApplicationState, state => {
  return state?.totalRoles;
});

export const selectPermissionList = createSelector(selectApplicationState, state => {
  return state?.permissions;
});

export const selectPermissionListCount = createSelector(selectApplicationState, state => {
  return state?.totalPermissions;
});

export const selectApplicationError = createSelector(selectApplicationState, state => {
  return {status: state?.applicationLoadingState, error: state?.error};
});

export const selectCreateEditPermissionError = createSelector(selectApplicationState, state => {
  return {status: state?.createEditPermissionState, error: state?.error};
});

export const selectbulkAddDeleteProductAppSuccess = createSelector(selectApplicationState, state => {
  return state?.bulkAddDeleteProductApp.succeedUpdated;
});

export const selectbulkAddDeleteProductAppFail = createSelector(selectApplicationState, state => {
  return state?.bulkAddDeleteProductApp?.failedUpdated;
});
