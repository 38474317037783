import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ComponentViewMode } from "@app/core/consts/app.const";
import { Role } from "@app/core/models/shared.model";
import { createRole, editRoleCheckDuplicate, getRoleById, resetEditRole } from "@app/shared/data-store/applications/application-data-store.actions";
import { selectApplicationError, selectRole } from "@app/shared/data-store/applications/application-data-store.selects";
import { Store } from "@ngrx/store";
import { ZonarUiNotificationsService } from "@zonar-ui/notifications";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { isBoolean } from 'lodash';
import { errorApiCode } from "@app/core/consts/common.const";

@Component({
  selector: 'app-create-edit-role-dialog',
  templateUrl: './create-edit-role-dialog.html',
  styleUrls: ['./create-edit-role-dialog.scss']
})
export class CreateEditRoleDialogComponent implements OnInit, OnDestroy {
  dialogMode: any;
  componentViewMode = ComponentViewMode;
  applicationId: string = '';
  defaultRowsDescription = 4;

  roleFormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    description: new FormControl(''),
  });
  roleId: string = '';
  initRole: any = {};
  currentRole: any = {};

  notificationDuration: number = 7;
  createRoleErrorMessage: string = 'There was an error while creating the role.';
  editRoleErrorMessage: string = 'There was an error while editing the role.';


  private onDestroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialogRef: MatDialogRef<CreateEditRoleDialogComponent>,
    private store: Store<any>,
    private zonarUiNotificationsService: ZonarUiNotificationsService,
  ) { }

  ngOnInit(): void {
    this.initDialogMode();
    if (this.dialogMode === ComponentViewMode.EDIT) {
      this.roleFormGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(formData => {
        this.currentRole = formData;
      });

      this.initEditModeRoleData();
    }

    this.registerAfterCallApiStatus();
  }

  initDialogMode() {
    this.applicationId = this.dialogData?.applicationId;
    this.roleId = this.dialogData?.roleId;
    this.dialogMode =  this.dialogData.roleId ? ComponentViewMode.EDIT : ComponentViewMode.CREATE;
  }

  initEditModeRoleData() {
    this.store.dispatch(getRoleById({ payload: { appId: this.applicationId, roleId: this.roleId } }));
    this.store.select(selectRole).pipe(takeUntil(this.onDestroy$))
      .subscribe((role) => {
        if (role) {
          const { name, description } = role;
          this.initRole = { name, description };
          this.roleFormGroup.patchValue({
            name: this.initRole.name,
            description: this.initRole.description
          });
        }
      });
  }

  isSaveButtonDisabled() {
    return this.roleFormGroup.status !== 'VALID'
      || !this.hasRoleNameValid()
      || (this.dialogMode === ComponentViewMode.EDIT && !this.hasRoleDataChanged());
  }

  hasRoleDataChanged() {
    return this.roleFormGroup.controls.name.value?.trim() !== this.initRole.name
    || this.roleFormGroup.controls.description.value?.trim() !== this.initRole.description;
  }

  hasRoleNameValid() {
    return this.roleFormGroup.controls.name.value?.trim() !== '';
  }

  onCloseDialog(data: any = {}) {
    this.dialogRef.close(data);
  }

  onSave() {
    const roleParams: Partial<Role> = {
      name: this.roleFormGroup.controls.name.value?.trim(),
      description: this.roleFormGroup.controls.description.value?.trim() || ''
    };

    this.dialogMode === ComponentViewMode.EDIT
      ? this.store.dispatch(editRoleCheckDuplicate({ payload: { applicationId: this.applicationId, roleId: this.roleId, editRoleParams: roleParams, initRole: this.initRole }}))
      : this.store.dispatch(createRole({ payload: { applicationId: this.applicationId, createRoleParams: roleParams }}));
  }

  registerAfterCallApiStatus() {
    this.store.select(selectApplicationError).pipe(takeUntil(this.onDestroy$))
      .subscribe(({ status, error }) => {
        if (isBoolean(status) && !status) {
          if (error) {
            if (error?.msgId === errorApiCode.CONFLICT_DISCOVERED) { // This handle duplicated role name within application, which is not handled on BE
              this.roleFormGroup.get('name')?.setErrors({
                duplicated: true
              });
            } else {
              this.zonarUiNotificationsService.openError(
                '',
                this.dialogMode === ComponentViewMode.EDIT ? this.editRoleErrorMessage : this.createRoleErrorMessage,
                this.notificationDuration
              );
              return;
            }
          } else {
            this.onCloseDialog({reloadTable: true});
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.store.dispatch(resetEditRole());
  }
}
