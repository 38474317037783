import { Component, Input, OnChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ListManagement, ListManagementType, SearchManagement } from 'src/app/core/models/list-management';
import { TableDataSource } from 'src/app/core/models/shared.model';

@Component({
  selector: 'app-list-management',
  templateUrl: './list-management.component.html',
  styleUrls: ['./list-management.component.scss']
})
export class ListManagementComponent implements OnInit, OnChanges {
  @Input() columns = new BehaviorSubject([]);
  @Input() dataSource!: TableDataSource;
  @Input() viewActions: ListManagement[] = [];
  @Input() displayedColumns: string[] = [];
  @Input() rowData = {};
  @Output() formChange = new EventEmitter<any>();

  searchesFormGroup: FormGroup = this.fb.group({});
  searchList: SearchManagement[] = [];
  buttonList = [];
  minSearchStringLength = 3;
  searching = false;
  searchParams: any = {};
  combineParams = {};

  constructor(
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnChanges(changes: any): void {
    if (changes.viewActions && changes.viewActions.currentValue.length) {
      this.searchList = this.buildSmallList(ListManagementType.Search);
      this.buttonList = this.buildSmallList(ListManagementType.Button);
      this.setSearchFormGroupData();
    }

  }

  ngOnInit(): void {
    this.searchesFormGroup.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
    )
    .subscribe((changes) => {
      Object.keys(changes).forEach((key) => {
        const str = changes[key]?.trim();
        if (str?.length >= this.minSearchStringLength) {
          this.searching = true;
          this.searchParams = { [key]: changes[key] };
        } else {
          if (this.searching) {
            this.onSearchReset();
          } else {
            this.searchParams = {};
          }
        }
      });
      this.combineParams = { ...this.searchParams };
      this.formChange.emit(this.combineParams);
    });
  }

  onSearchReset() {
    this.searching = false;
    this.searchParams = null;
  }

  buildSmallList(typeManagement: ListManagementType) {
    return this.viewActions.reduce((pre: any, cur: ListManagement) =>
      typeManagement === cur?.type ? [...pre,
      typeManagement === ListManagementType.Search ? cur?.searchManagement : cur?.buttonManagement] : pre, []);
  }

  onClicked(buttonItem: any) {
    switch (typeof buttonItem?.actionInput) {
      case "string":
        this.router.navigate([buttonItem.actionInput], { state: { data: buttonItem.data } });
        break;
      case "function":
        buttonItem.actionInput(buttonItem.data);
        break;
    }
  }

  setSearchFormGroupData() {
    if (this.searchList && this.searchList.length) {
      this.searchList.forEach((search: any) => {
        if (!this.searchesFormGroup.controls[search.controlName]) {
          this.searchesFormGroup.registerControl(search.controlName, new FormControl(''));
        }
      });
    }
  }

}
