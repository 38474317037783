<div class="list-container" fxLayout="row" fxLayoutAlign="space-between none">

  <!-- Left selection list -->
  <mat-card fxFlex="19rem">
    <div class="header-label" mat-subheader fxLayout="row" fxLayoutAlign="center center" >{{headerLabels?.available}}</div>
    <div class="option-box">
      <mat-selection-list class="option-list" #availableList (selectionChange)="onLeftSelection(availableList.selectedOptions.selected)">
          <mat-list-option *ngFor="let option of availableOptions" [value]="option?.id" checkboxPosition="before">
            <span class="no-wrap">{{option?.name}}</span>
            <mat-divider [inset]="true"></mat-divider>
          </mat-list-option>
      </mat-selection-list>
    </div>
  </mat-card>

  <!-- Move button -->
    <div class="action-buttons" fxLayout="column" fxLayoutAlign="center none">
      <button mat-button (click)="transferRightAll()" [disabled]="!availableOptions.length">≫</button>
      <button mat-button (click)="transferRight()" [disabled]="!selectedLeftIds.length">></button>
      <button mat-button (click)="transferLeft()" [disabled]="!selectedRightIds.length"><</button>
      <button mat-button (click)="transferLeftAll()" [disabled]="!selected.length">≪</button>
    </div>

  <!-- Right selection list -->
  <mat-card fxFlex="19rem">
    <div class="header-label" mat-subheader fxLayout="row" fxLayoutAlign="center center" >{{headerLabels?.selected}}</div>
    <div class="option-box">
      <mat-selection-list class="option-list" #selectedList (selectionChange)="onRightSelection(selectedList.selectedOptions.selected)">
          <mat-list-option *ngFor="let option of selected" [value]="option?.id" checkboxPosition="before">
            <span class="no-wrap">{{option?.name}}</span>
            <mat-divider [inset]="true"></mat-divider>
          </mat-list-option>
      </mat-selection-list>
    </div>
  </mat-card>

</div>
