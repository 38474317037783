<div class="guidance-inner-container">
  <ng-container *ngIf="contentKeys.includes(ContentKeys.Product)">
    <h3>Product</h3>
    <p>Products are the software services that customers subscribe for. When a new customer or an existing customer
      purchase a service, internal sales/implementation team will refer to a price sheet and turn on these services on
      company detail page in Admin+. This streamlines the process of setting up and configuring new customers.</p>
    <p>When a product is turned on for a company, all applications and roles associated with that product will show up
      on the Admin+ user management (permission templates).</p>
    <ul data-indent-level="1">
      <li>
        <p><strong data-renderer-mark="true">Product name:</strong> Unique name that identifies the product and is used
          across the company for billing and identification purpose. Appears on Admin+ Company details page.</p>
      </li>
      <li>
        <p><strong data-renderer-mark="true">Product description: </strong>A clear detailed explanation of products
          features and benefits that helps anyone in the company easily identify its purpose &amp; value</p>
      </li>
    </ul>

    <br><br>
  </ng-container>

  <ng-container *ngIf="contentKeys.includes(ContentKeys.Application)">
    <h3>Application</h3>
    <p>When a customer purchase a product/service, they are granted access to all of the applications included in that
      product.</p>
    <ul>
      <li>
        <p><strong>Application name: </strong>Unique name that identifies the application within the product suite.
          Might appear on the Admin+ user management (permission templates).</p>
      </li>
      <li>
        <p><strong>Description: </strong>A clear concise explanation of purpose, functionality that helps anyone in the
          company easily understand how this fits into the larger product suite and how it is used to achieve specifc
          goals/solve particular problems.</p>
      </li>
    </ul>
    <br><br>
  </ng-container>


  <ng-container *ngIf="contentKeys.includes(ContentKeys.RolesPermissions)">
    <h3>Roles &amp; Permissions</h3>
    <ul>
      <li>
        <p><strong>Roles</strong>: Roles are permissions grouped together which determines authorization or access level
          within an application. Appears on the Admin+ user management (permission templates) through which a customer
          admin can control the level of access a user can have across applications.</p>
      </li>
      <li>
        <p><strong>Permissions</strong>: Permissions are simple strings that control and limit access to
          features/component visibility on the UI in an application. Each application should have atleast one permission
          defined. These are not directly exposed to the end user.</p>
        <ul>
          <li>
            <p>E.g. user management in Admin+ can have individual permissions to control CRUD operations such as
              “self_service:create:user", "self_service:update:user", "self_service:delete:user",
              "self_service:read:users"</p>
          </li>
        </ul>
      </li>
    </ul>
    <br><br>
  </ng-container>

  <ng-container *ngIf="contentKeys.includes(ContentKeys.GroupPolicyTemplate)">
    <h3>Group Policy Template</h3>
    <p>The Group Policy template refers to a pre-structured format used as a starting point for creating Policy. Templates are used in a variety of contexts to save time and maintain consistency.</p>
    <ul>
      <li>
        <p><strong>Group Policy name: </strong>Name of the template.</p>
      </li>
      <li>
        <p><strong>Description: </strong>A clear concise explanation of purpose, functionality that helps anyone in the
          company easily understand how this fits into the larger product suite and how it is used to achieve specifc
          goals/solve particular problems.</p>
      </li>
    </ul>
    <br><br>
  </ng-container>
</div>