import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { EntityApiBaseService } from './entity-api-base.service';
import { environment as env } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GroupPolicyApiService extends EntityApiBaseService {
  private apiUrl = env.coreEntityApiBase.url;
  private GROUP_POLICY_RESOURCE = 'policytemplates';

  getGroupPolicyList(params?: any): Observable<any> {
    const url = `${this.apiUrl}/${this.GROUP_POLICY_RESOURCE}`;
    return this.getData(url, params);
  }

  getGroupPolicyById(id: string): Observable<any> {
    const url = `${this.apiUrl}/${this.GROUP_POLICY_RESOURCE}/${id}`;
    return this.getData(url);
  }

  createGroupPolicy(params: any) {
    const url = `${this.apiUrl}/${this.GROUP_POLICY_RESOURCE}`;
    return this.http.post(url, params);
  }

  putGroupPolicyTemplate(templateId: string, params: any) {
    const url = `${this.apiUrl}/${this.GROUP_POLICY_RESOURCE}/${templateId}`;
    return this.http.put(url, params);
  }

  patchGroupPolicyTemplate(templateId: string, params: any) {
    const url = `${this.apiUrl}/${this.GROUP_POLICY_RESOURCE}/${templateId}`;
    return this.http.patch(url, params);
  }
}
