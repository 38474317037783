import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnDestroy } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject, Subject } from 'rxjs';
import { TreeNode } from './tree-node.model';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-tree',
  templateUrl: './tree-base.component.html',
  styleUrls: ['./tree-base.component.scss']
})
export class TreeBaseComponent implements OnDestroy {
  treeNodeList: Array<TreeNode> = [];
  treeChanged = new BehaviorSubject<Array<TreeNode>>([]);
  flatNodeMap = new Map<TreeNode, TreeNode>();
  nestedNodeMap = new Map<TreeNode, TreeNode>();
  treeControl: FlatTreeControl<TreeNode>;
  treeFlattener: MatTreeFlattener<TreeNode, TreeNode>;
  treeDataSource: MatTreeFlatDataSource<TreeNode, TreeNode>;
  checklistSelection = new SelectionModel<TreeNode>(true /* multiple */);
  tmpDataSource;

  protected onDestroy$ = new Subject<void>();

  constructor() {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    );
    this.treeControl = new FlatTreeControl<TreeNode>(this.getLevel, this.isExpandable);
    this.treeDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.treeChanged.subscribe((dataChanged: Array<TreeNode>) => {
      this.treeDataSource.data = dataChanged;
    });
  }

  getLevel = (node: TreeNode) => node?.level;

  isExpandable = (node: TreeNode) => node?.expandable;

  getChildren = (node: TreeNode): Array<TreeNode> => node.children;

  hasChild = (_: number, node: TreeNode) => node?.expandable;


  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TreeNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.name === node.name ? existingNode : new TreeNode();
    flatNode.name = node.name;
    flatNode.level = level;
    flatNode.children = node.children;
    flatNode.id = node.id;
    flatNode.selected = node.selected || false;
    // flatNode.disabled = node.disabled;
    flatNode.expandable = !!node?.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  selectAndDisabledDescendants(node, disableSubItemSelection): void {
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.select(...descendants);

    // Force update for disabled children
    descendants.forEach((child: TreeNode) => {
      // child.disabled = disableSubItemSelection;
      this.updateSelectedChildrenNode(this.tmpDataSource, node, true);
    });
  }

  updateSelectedParentNode(array, node, isSelected) {
    for (let item of array) {
      if (item?.id === node.id) {
        item.selected = isSelected;
        return;
      }
    }
  }

  updateSelectedChildrenNode(array, node, isSelected = null, isUpdateParentNode = true) {
    if (!node) {
      for (let item of array) {
        item.selected = isUpdateParentNode ? isSelected : (item.disabled ? false : isSelected);
        if (item.children?.length) {
          this.updateSelectedChildrenNode(item.children, null, isSelected);
        }
      }
    } else {
      for (let item of array) {
        if (item?.id === node.id) {
          item.selected = isUpdateParentNode ? isSelected : false;
          if (item.children?.length) {
            this.updateSelectedChildrenNode(item.children, null, isSelected);
          }

          return;
        } else if (item.children?.length) {
          this.updateSelectedChildrenNode(item.children, node, isSelected);
        }
      }
    }
  }

  buildNestedTree(treeItems: Array<TreeNode>, ignoreCondition = false): Array<TreeNode> {
    const nestedItems: Array<TreeNode> = [];
    treeItems.forEach((item: TreeNode) => {
      if (!item.parentId || ignoreCondition) {
        item.children = this.buildChildrenTree(item);
        nestedItems.push(item);
        this.buildNestedTree(item.children, true);
      }
    });
    return nestedItems;
  }

  buildChildrenTree(treeItem: TreeNode): Array<TreeNode> {
    const treeContentsClone = cloneDeep(this.treeNodeList);
    const children = [];
    treeItem.children.forEach(child => {
      const index = treeContentsClone.findIndex(item => child.id ? item.id === child.id : item.id === child);
      if (index !== -1) {
        children.push(treeContentsClone[index]);
        treeContentsClone.splice(index, 1);
      }
    });
    return children;
  }


  /* Get the parent node of a node */
  getParentNode(node: TreeNode): TreeNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node);

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  expandParents(node: TreeNode): void {
    let parent: TreeNode | null = this.getParentNode(node);
    this.treeControl.expandDescendants(node);
    while (parent) {
      this.treeControl.expand(parent);
      parent = this.getParentNode(parent);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
