import { DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs';

export enum TableColumnCellType {
  Text = 'text',
  Button = 'button',
  Link = 'link',
  Row = 'row'
}

export interface TableColumnModel {
  type?: TableColumnCellType;
  columnDef: string;
  headerCellDef?: string;
  cell: (row: any) => string;
  buttonOnClick?: (row: any) => void;
  hidden?: boolean
}

export interface TableDataSource extends DataSource<[]> {
  total$: Observable<number>;
  loadData(params?: {}): void;
}

export interface PaginatorChangeEventModel {
  length: number;
  pageIndex: number;
  pageSize: number;
  previousPageIndex: number;
}

export interface RowModel {
  id?: string,
  name: string
}
export interface ChipChanged {
  addChipList: RowModel[],
  removeChipList: RowModel[],
  resultChipList: RowModel[]
}

export interface ChipDataDialog {
  chipList: RowModel[],
  allChips: RowModel[],
  rowData: any
}

export interface SidenavItemModel {
  text: string; // text of menu item
  icon: string; // icon name
  route?: string; // route to take user to on click
  routerLink?: string;
  routeLevel: number;
  appId?: string; // appId of the route
  isSelected?: boolean; // highlight the item
  children?: SidenavItemModel[]; // sub menu items
  isExpanded: boolean; // expand the children
}

export interface Product {
  name?: string,
  description?: string,
  type?: string,
  sku?: string,
  id?: string
}

export interface Role {
  description: string,
  type: string,
  permissions: string[],
  id: string,
  name: string
}

export interface Application {
  roles: Role[],
  permissions: string[],
  description: string,
  name: string,
  type: string,
  products: string[],
  identityProviderId?: string,
  allowManagedUsers?: boolean
}

export interface ITransferList {
  all: ITransferListItem[]
  selected: ITransferListItem[]
}

export interface ITransferListItem {
  id: string;
  name: string;
}

export interface ApiError {
  detail: string;
  msgId: string;
  param: any;
}

export interface GroupPolicyTemplate {
  name: string,
  description: string,
  jobType: boolean,
  public: boolean,
  status: boolean,
  externallyManaged: boolean,
  policy: any[],
  grants?: any[],
}

export enum AssetTablePropertyParams {
  PER_PAGE = 'per_page',
  PAGE = 'page',
  NAME = 'name',
  STATUS = 'status',
  TYPES = 'types',
  IDS = 'ids',
  DIVISIONS = 'divisions',
  PROPERTIES = 'properties',
  SORT = 'sort'
}
