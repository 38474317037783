import { Application, GroupPolicyTemplate } from '@app/core/models/shared.model';

/* state interface */
export interface GroupPolicyState {
  groupPolicyList: GroupPolicyTemplate[] | null,
  groupPolicyCount: number,
  productDetail: {
    groupPolicy: GroupPolicyTemplate | null
    applications: Application[] | null;
    totalApplications: number | null;
  };
  groupPolicyDetail: GroupPolicyTemplate;
  createEditPolicySuccess: boolean;
}

/* initial values */
export const initialState: GroupPolicyState = {
  groupPolicyList: null,
  groupPolicyCount: null,
  productDetail: {
    groupPolicy:  null,
    applications:  null,
    totalApplications:  null
  },
  groupPolicyDetail: null,
  createEditPolicySuccess: null,
};
