/* angular */
import { createReducer, on } from '@ngrx/store';

/* services */

/* actions */
import * as GroupPolicyDataStoreActions from './group-policy-data-store.actions';
import { initialState } from './group-policy-data-store.interfaces';

/* state key */
export const featureKey = 'group-policy';


export const reducer = createReducer(
  initialState,
  /* reducer functions for actions */
  on(GroupPolicyDataStoreActions.initGroupPolicy, (state, action) => ({
    ...state,
    loading: true
  })),
  on(GroupPolicyDataStoreActions.getGroupPolicyListSuccessfully, (state, action) => ({
    ...state,
    groupPolicyList: action.payload.groupPolicyList,
    groupPolicyCount: action.payload.groupPolicyCount
  })),

  on(GroupPolicyDataStoreActions.getGroupPolicyByIdSuccessfully, (state, action) => ({
    ...state,
    groupPolicyDetail: action.payload.groupPolicy,
  })),

  on(GroupPolicyDataStoreActions.createGroupPolicy, (state, action) => ({
    ...state,
    createEditPolicySuccess: false,
  })),
  
  on(GroupPolicyDataStoreActions.createGroupPolicySuccessfully, (state, action) => ({
    ...state,
    createEditPolicySuccess: true,
  })),

  on(GroupPolicyDataStoreActions.updateGroupPolicy, (state, action) => ({
    ...state,
    createEditPolicySuccess: false,
  })),

  on(GroupPolicyDataStoreActions.updateGroupPolicySuccessfully, (state, action) => ({
    ...state,
    createEditPolicySuccess: true,
  })),

  on(GroupPolicyDataStoreActions.resetPolicy, (state, action) => {
    state = {...state}; 
    Object.keys(action.payload).forEach(key => {
      state[key] = action.payload.key;
    });
    return {...state};
  }),

);
