/* angular */
import { createReducer, on } from '@ngrx/store';

/* services */

/* actions */
import * as ApplicationDataStoreActions from './application-data-store.actions';
import { initialState } from './application-data-store.interfaces';

/* state key */
export const featureKey = 'application';


export const reducer = createReducer(
  initialState,
  /* reducer functions for actions */
  on(ApplicationDataStoreActions.initApplication, (state, action) => ({
    ...state,
    loading: true
  })),
  // #region BULK ADD DELETE PRODUCT'S APPS
  on(ApplicationDataStoreActions.bulkAddDeleteProductAppSuccess, (state, action) => ({
    ...state,
    bulkAddDeleteProductApp: {
      ...state.bulkAddDeleteProductApp,
      succeedUpdated: [...state.bulkAddDeleteProductApp.succeedUpdated, action.payload.applicationId],
    }
  })),
  on(ApplicationDataStoreActions.bulkAddDeleteProductAppFail, (state, action) => ({
    ...state,
    bulkAddDeleteProductApp: {
      ...state.bulkAddDeleteProductApp,
      failedUpdated: [...state.bulkAddDeleteProductApp.failedUpdated, action.payload.applicationId],
    }
  })),
  on(ApplicationDataStoreActions.bulkAddDeleteProductAppReset, (state, action) => ({
    ...state,
    bulkAddDeleteProductApp: {
      ...state.bulkAddDeleteProductApp,
      succeedUpdated: [],
      failedUpdated: [],
    }
  })),
  // #endregion BULK ADD DELETE PRODUCT'S APPS
  on(ApplicationDataStoreActions.getApplicationListSuccess, (state, action) => ({
    ...state,
    loading: false,
    applicationList: action.payload.applicationList,
    applicationCount: action.payload.applicationCount
  })),

  on(ApplicationDataStoreActions.getAllApplicationsSuccess, (state, action) => ({
    ...state,
    loading: false,
    allApplications: action.payload.allApplications,
  })),

  on(ApplicationDataStoreActions.getApplicationByIdSuccess, (state, action) => ({
    ...state,
    loading: false,
    application: action.payload
  })),

  on(ApplicationDataStoreActions.getRoleByIdSuccess, (state, action) => ({
    ...state,
    loading: false,
    role: action.payload
  })),

  on(ApplicationDataStoreActions.getRoleListByAppIdSuccess, (state, action) => ({
    ...state,
    loading: false,
    roles: action.payload.roles,
    totalRoles: action.payload.totalRoles
  })),

  on(ApplicationDataStoreActions.getPermissionListByAppIdSuccess, (state, action) => ({
    ...state,
    loading: false,
    permissions: action.payload.permissions,
    totalPermissions: action.payload.totalPermissions
  })),

  on(ApplicationDataStoreActions.createApplication, (state, action) => ({
    ...state,
    applicationLoadingState: true,
    error: null
  })),

  on(ApplicationDataStoreActions.createApplicationSuccess, (state, action) => ({
    ...state,
    applicationLoadingState: false,
    application: action.payload.application,
    error: null
  })),

  on(ApplicationDataStoreActions.createApplicationFailure, (state, action) => ({
    ...state,
    applicationLoadingState: false,
    error: action.payload.error,
  })),

  on(ApplicationDataStoreActions.editApplication, (state, action) => ({
    ...state,
    applicationLoadingState: true,
    error: null
  })),

  on(ApplicationDataStoreActions.editApplicationSuccess, (state, action) => ({
    ...state,
    applicationLoadingState: false,
    error: null
  })),

  on(ApplicationDataStoreActions.editApplicationFailure, (state, action) => ({
    ...state,
    applicationLoadingState: false,
    error: action.payload.error,
  })),

  on(ApplicationDataStoreActions.editApplicationPermission, (state, action) => ({
    ...state,
    createEditPermissionState: true,
    error: null,
  })),

  on(ApplicationDataStoreActions.createApplicationPermission, (state, action) => ({
    ...state,
    createEditPermissionState: true,
    error: null,
  })),

  on(ApplicationDataStoreActions.createEditApplicationPermissionSuccess, (state, action) => ({
    ...state,
    createEditPermissionState: false,
    error: null,
  })),

  on(ApplicationDataStoreActions.createEditApplicationPermissionFailure, (state, action) => ({
    ...state,
    createEditPermissionState: false,
    error: action.payload.error,
  })),

  on(ApplicationDataStoreActions.resetEditApplicationPermissionState, (state, action) => ({
    ...state,
    createEditPermissionState: null,
    error: null,
  })),

  on(ApplicationDataStoreActions.resetApplicationLoadingState, (state, action) => ({
    ...state,
    applicationLoadingState: null,
    error: null,
    application: null
  })),
  /* create application's role reducers */
  on(ApplicationDataStoreActions.createRole, (state, action) => ({
    ...state,
    applicationLoadingState: true
  })),
  on(ApplicationDataStoreActions.createRoleSuccess, (state, action) => ({
    ...state,
    applicationLoadingState: false,
    role: action.payload.role,
    error: null
  })),
  on(ApplicationDataStoreActions.createRoleFailure, (state, action) => ({
    ...state,
    applicationLoadingState: false,
    error: action.payload.error
  })),
  /* edit application's role reducers */
  on(ApplicationDataStoreActions.editRoleCheckDuplicate, (state, action) => ({
    ...state,
    applicationLoadingState: true
  })),
  on(ApplicationDataStoreActions.editRoleSuccess, (state, action) => ({
    ...state,
    applicationLoadingState: false,
    role: action.payload.role,
    error: null
  })),
  on(ApplicationDataStoreActions.editRoleFailure, (state, action) => ({
    ...state,
    applicationLoadingState: false,
    error: action.payload.error
  })),
  /* general create/edit application's role reducers */
  on(ApplicationDataStoreActions.roleDuplicate, (state, action) => ({
    ...state,
    applicationLoadingState: false,
    error: action.payload.error
  })),
  on(ApplicationDataStoreActions.resetEditRole, (state, action) => ({
    ...state,
    applicationLoadingState: null,
    role: null,
    error: null
  })),
  // #region RESET
  on(ApplicationDataStoreActions.resetRole, (state, action) => ({
    ...state,
    role: null,
  })),
  on(ApplicationDataStoreActions.resetApplication, (state, action) => ({
    ...state,
    application: null,
  })),
  // #endregion RESET
);
