import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PaginatorChangeEventModel, TableColumnCellType, TableColumnModel, TableDataSource } from 'src/app/core/models/shared.model';

@Component({
  selector: 'app-table-template',
  templateUrl: './table-template.component.html',
  styleUrls: ['./table-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableTemplateComponent implements OnChanges {
  @Input() columns!: BehaviorSubject<TableColumnModel[]>;
  @Input() dataSource!: TableDataSource;
  @Input() displayedColumns!: string[];
  @Input() rowData = {};

  columnCellType = TableColumnCellType;
  columnsArray!: TableColumnModel[];
  initialPageSize = 10;
  totalRow = 0;

  hasLoadSuccess: boolean = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    const isColumnsInit = changes.columns ? changes.columns.currentValue : this.columns;
    const isDataSourceInit = changes.dataSource ? changes.dataSource.currentValue : this.dataSource;
    const isDisplayedColumnsInit = changes.displayedColumns ? changes.displayedColumns.currentValue : this.displayedColumns;

    if (isColumnsInit && isDataSourceInit && isDisplayedColumnsInit) {
      this.initComponent();
    }
  }

  initComponent() {
    this.dataSource.loadData({ page: 1, per_page: this.initialPageSize });
    this.columns?.subscribe((cols: TableColumnModel[]) => {
      this.columnsArray = cols.slice();
      this.displayedColumns = this.getDerivedDisplayColumns(this.columnsArray);
    });
    this.dataSource.total$.subscribe((totalRow: number) => {
      this.totalRow = totalRow;
    });
    this.hasLoadSuccess = true;
  }

  onPaginatorChange(event: PaginatorChangeEventModel) {
    this.dataSource.loadData({ page: event.pageIndex + 1, per_page: event.pageSize });
  }

  getCellContext(data: string) {
    if (data === null) {
      return 'error data';
    }
    return data;
  }

  getDerivedDisplayColumns(columns: TableColumnModel[]): string[] {
    return columns.filter((col: TableColumnModel) => !col.hidden).map((col: TableColumnModel) => col.columnDef);
  }

  getCellTooltip(element: any, data: string) {
    if (element) {
      return element.scrollWidth > element.clientWidth ? data : null;
    }
    return false;
  }
}
