/* Angular */
import { Product } from '@app/core/models/shared.model';
import { createAction, props } from '@ngrx/store';
import { GetProductApplicationsPayload, GetProductApplicationsSuccessPayload } from './product-data-store.interfaces';

/* action types */
export enum ActionTypes {
    Init = '[PRODUCT] Init',

    GetProductList = '[PRODUCT] GetProductList',
    GetProductListSuccess = '[PRODUCT] GetProductListSuccess',
    /* actions types for product detail */
    GetProductById = '[PRODUCT] GetProductById',
    GetProductByIdSuccess = '[PRODUCT] GetProductByIdSuccess',
    CreateProduct = '[PRODUCT] CreateProduct',
    CreateProductSuccess = '[PRODUCT] CreateProductSuccess',
    CreateProductFailure = '[PRODUCT] CreateProductFailure',
    EditProduct = '[PRODUCT] EditProduct',
    EditProductSuccess = '[PRODUCT] EditProductSuccess',
    EditProductFailure = '[PRODUCT] EditProductFailure',
    ResetProductLoadingState = '[PRODUCT] ResetProductLoadingState',

    GetProductApplications = '[APPLICATION] GetProductApplications',
    GetProductApplicationsSuccess = '[APPLICATION] GetProductApplicationsSuccess',
    ResetProductDetailProduct = '[PRODUCT] ResetProductDetailProduct',
    ResetProductDetailProductApp = '[PRODUCT] ResetProductDetailProductApp',
}

/* actions */
export const initProduct = createAction(ActionTypes.Init);
export const getProductList = createAction(ActionTypes.GetProductList, props<{ payload: any }>());
export const getProductListSuccess = createAction(ActionTypes.GetProductListSuccess, props<{ payload: { productList: Product[], productCount: number } }>());
/* actions for product detail */
export const getProductById = createAction(ActionTypes.GetProductById, props<{ payload: string }>());
export const getProductByIdSuccess = createAction(ActionTypes.GetProductByIdSuccess, props<{ payload: Product | null }>());
export const getProductApplications = createAction(ActionTypes.GetProductApplications, props<{ payload: GetProductApplicationsPayload }>());
export const getProductApplicationsSuccess = createAction(ActionTypes.GetProductApplicationsSuccess, props<{ payload: GetProductApplicationsSuccessPayload }>());

export const createProduct = createAction(ActionTypes.CreateProduct, props<{ payload: { appId?: string, params: Product } }>());
export const createProductSuccess = createAction(ActionTypes.CreateProductSuccess, props<{ payload: { productCreated: Product } }>());
export const createProductFailure = createAction(ActionTypes.CreateProductFailure, props<{ payload: any }>());

export const editProduct = createAction(ActionTypes.EditProduct, props<{ payload: { productId: string, params: Product } }>());
export const editProductSuccess = createAction(ActionTypes.EditProductSuccess);
export const editProductFailure = createAction(ActionTypes.EditProductFailure, props<{ payload: any }>());
export const resetProductLoadingState = createAction(ActionTypes.ResetProductLoadingState);
export const resetProductDetailProduct = createAction(ActionTypes.ResetProductDetailProduct);
export const resetProductDetailProductApp = createAction(ActionTypes.ResetProductDetailProductApp);
