<div class="data-list-container">
  <app-search [searchFormControl]="searchControl"
  placeHolder="Search by product name" class="search-container"></app-search>
  <mat-tree [dataSource]="treeDataSource" [treeControl]="treeControl" class="tree-container">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding matTreeNodePaddingIndent="22">
      <button mat-icon-button disabled></button>
      <mat-checkbox [disableRipple]="true" [disabled]="node.disabled || isReadOnly" class="checklist-leaf-node"
        [checked]="checklistSelection.isSelected(node)" (change)="todoLeafItemSelectionToggle(node)">{{node.name}}
      </mat-checkbox>
    </mat-tree-node>

    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="22">
      <button [disableRipple]="true" mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name"
        class="icon-container" [disabled]="isReadOnly">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'expand_less'}}
        </mat-icon>
      </button>
      <mat-checkbox [disableRipple]="true" [disabled]="node.disabled || isReadOnly" #myCheckbox [checked]="enableDeterminate ? descendantsAllSelected(node) : checklistSelection.isSelected(node)"
        (click)="todoItemSelectionToggle(node, $event)" [indeterminate]="enableDeterminate ? descendantsPartiallySelected(node) : null">{{node.name}}</mat-checkbox>
    </mat-tree-node>
  </mat-tree>
</div>
