/* Angular */
import { GroupPolicyTemplate } from '@app/core/models/shared.model';
import { createAction, props } from '@ngrx/store';

/* action types */
export enum ActionTypes {
  Init = '[GROUP_POLICY] Init',
  GetGroupPolicyList = '[GROUP_POLICY] GetGroupPolicyList',
  GetGroupPolicyListSuccessfully = '[GROUP_POLICY] GetGroupPolicyListSuccessfully',
  GetGroupPolicyListFailure = '[GROUP_POLICY] GetGroupPolicyListFailure',
  GetGroupPolicyById = '[GROUP_POLICY] GetGroupPolicyById',
  GetGroupPolicyByIdSuccessfully = '[GROUP_POLICY] GetGroupPolicyByIdSuccessfully',
  CreateGroupPolicy = '[GROUP_POLICY] CreateGroupPolicy',
  CreateGroupPolicySuccessfully = '[GROUP_POLICY] CreateGroupPolicySuccessfully',
  UpdateGroupPolicy = '[GROUP_POLICY] UpdateGroupPolicy',
  UpdateGroupPolicySuccessfully = '[GROUP_POLICY] UpdateGroupPolicySuccessfully',
  ResetPolicy = '[GROUP_POLICY] ResetPolicy',
}

/* actions */
export const initGroupPolicy = createAction(ActionTypes.Init);
export const getGroupPolicyList = createAction(ActionTypes.GetGroupPolicyList, props<{ payload: { params: any } }>());
export const getGroupPolicyListSuccessfully = createAction(ActionTypes.GetGroupPolicyListSuccessfully,
  props<{ payload: any }>());
export const getGroupPolicyById = createAction(ActionTypes.GetGroupPolicyById,
  props<{ payload: { groupId: string } }>());
export const getGroupPolicyByIdSuccessfully = createAction(ActionTypes.GetGroupPolicyByIdSuccessfully,
  props<{ payload: { groupPolicy: GroupPolicyTemplate } }>());

export const createGroupPolicy = createAction(ActionTypes.CreateGroupPolicy,
  props<{ payload: any }>());
export const createGroupPolicySuccessfully = createAction(ActionTypes.CreateGroupPolicySuccessfully);

export const updateGroupPolicy = createAction(ActionTypes.UpdateGroupPolicy,
  props<{ payload: any }>());
export const updateGroupPolicySuccessfully = createAction(ActionTypes.UpdateGroupPolicySuccessfully);

export const resetPolicy = createAction(ActionTypes.ResetPolicy,  props<{ payload: any }>());
