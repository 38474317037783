import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, mergeMap } from 'rxjs/operators';
/* actions */
import * as ProductActions from './product-data-store.actions';
import { ProductApiService } from '@app/core/services/api/product-api.service';
import { HttpResponse } from '@angular/common/http';
import { Product } from '@app/core/models/shared.model';
import { ApplicationApiService } from '@app/core/services/api/application-api.service';

@Injectable()
export class ProductDataStoreEffects {
  constructor(
    private actions$: Actions,
    private productApiService: ProductApiService,
    private applicationApiService: ApplicationApiService,
    private store: Store<any>,
  ) { }

  getProductList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.getProductList),
      mergeMap((action) => {
        return this.productApiService.getProductList(action.payload).pipe(
          mergeMap((res: any) => {
            return [ProductActions.getProductListSuccess({
              payload: {
                productList: res?.body || [],
                productCount: +res.headers.get('x-total-count')
              }
            })];
          })
        );
      })
    )
  );

  getProductById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.getProductById),
      mergeMap((action) => {
        return this.productApiService.getProductById(action.payload).pipe(
          mergeMap((res: HttpResponse<Product>) => {
            return [ProductActions.getProductByIdSuccess({
              payload: res?.body || null
            })];
          })
        );
      })
    )
  );

  getProductApplications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.getProductApplications),
      mergeMap((action) => {
        const { productId, params } = action.payload;
        return this.productApiService.getProductApplications(productId, params).pipe(
          mergeMap((res: any) => {
            return [ProductActions.getProductApplicationsSuccess({
              payload: {
                applications: res?.body,
                totalApplications: +res.headers.get('x-total-count')
              }
            })];
          })
        );
      })
    )
  );

  createProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.createProduct),
      mergeMap((action) => {
        const { appId, params } = action.payload;
        return this.productApiService.createProduct(params).pipe(
          mergeMap((res: any) => {
            /**
             * Case haven't application ID, we will create new product 
             * Case have application ID, we will create new product, this product is related to an application
             */
            if (!appId) {
              return [ProductActions.createProductSuccess({ payload: { productCreated: res.body } })];
            } else {
              const productId = res.body.id;
              return this.applicationApiService.addProductToApplication(appId, [productId]).pipe(
                mergeMap(() => {
                  return [ProductActions.createProductSuccess({ payload: { productCreated: res.body } })];
                }),
                catchError((error) => {
                  return [ProductActions.createProductFailure({
                    payload: { error }
                  })];
                })
              );
            }
          }),
          catchError((error) => {
            return [ProductActions.createProductFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  editProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.editProduct),
      mergeMap((action) => {
        const { productId, params } = action.payload;
        return this.productApiService.editProduct(productId, params).pipe(
          mergeMap(() => {
            return [ProductActions.editProductSuccess()];
          }),
          catchError((error) => {
            return [ProductActions.editProductFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );
}