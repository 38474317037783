import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isEqual, differenceWith, includes, filter, remove} from 'lodash';
import { MatListOption } from '@angular/material/list';

import { ITransferList, ITransferListItem } from '@app/core/models/shared.model';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-transfer-list',
  templateUrl: './transfer-list.component.html',
  styleUrls: ['./transfer-list.component.scss']
})
export class TransferListComponent implements OnInit {
  @Input() options: ITransferList | any = {};
  @Input() headerLabels: { available: string, selected: string } | any = {
    available: 'Available',
    selected: 'Selected'
  };
  allOptions: Array<ITransferListItem> = [];
  selected: Array<ITransferListItem> = [];
  selectedLeftIds: string[] = [];
  selectedRightIds: string[] = [];
  @Output() selectedEmit = new EventEmitter<Array<string> | null>();

  constructor() { }

  ngOnInit() {
    this.setupDefaultSelections();
  }

  setupDefaultSelections() {
    const options = cloneDeep(this.options);
    this.allOptions = options?.all || [];
    this.selected = options?.selected || [];
  }

  get availableOptions() {
    return differenceWith(this.allOptions, this.selected, isEqual);
  }

  onLeftSelection(options: MatListOption[]) {
    this.selectedLeftIds = [];
    options.map(opt => {
      this.selectedLeftIds.push(opt.value);
    });
  }

  onRightSelection(options: MatListOption[])  {
    this.selectedRightIds = [];
    options.map(opt => {
      this.selectedRightIds.push(opt.value);
    });
  }


  transferRight(ids: string[] = this.selectedLeftIds) {
    this.selectedLeftIds = [];
    //Find elements by id in available list
    const selectedElements =  filter(this.allOptions, (element) => includes(ids, element.id));
    this.selected = [...this.selected, ...selectedElements];
    this.emitDataUponTransferring();
  }

  transferRightAll() {
    this.selected = [...this.allOptions];
    this.emitDataUponTransferring();
  }

  transferLeft(ids: string[] = this.selectedRightIds) {
    this.selectedRightIds = [];
    //Find elements by id in available list
    const selectedElements =  filter(this.selected, (element) => includes(ids, element.id));
    this.selected = remove(this.selected, (element) => {
        return selectedElements.findIndex(elm => element === elm) < 0;
    });
    this.emitDataUponTransferring();
  }

  transferLeftAll() {
    this.selected = [];
    this.emitDataUponTransferring();
  }

  emitDataUponTransferring() {
    if (isEqual(this.selected, this.options?.selected)) { // emit for reset
      this.selectedEmit.emit(null);
    } else {
      this.selectedEmit.emit(this.selected.map(select => select.id));
    }
  }
}
