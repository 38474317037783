import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListManagementComponent } from './components/list-management/list-management.component';
import { SearchComponent } from './components/search/search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { TableTemplateComponent } from './components/table-template/table-template.component';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TransferListComponent } from './components/transfer-list/transfer-list.component';
import { MatListModule } from '@angular/material/list';
import { ChipSelectComponent } from './components/chip-select/chip-select.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatGridListModule } from '@angular/material/grid-list';
import { RowByRowComponent } from './components/row-by-row/row-by-row.component';
import { MatDialogModule } from '@angular/material/dialog';
import { RoleEditComponent } from './dialogs/role-edit/role-edit.component';
import { DetailListComponent } from './components/detail-list/detail-list.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TransferDialogComponent } from './dialogs/transfer-dialog/transfer-dialog.component';
import { CreateEditRoleDialogComponent } from './dialogs/create-edit-role-dialog/create-edit-role-dialog';
import { CreateEditDialogComponent } from './dialogs/create-edit-permission-dialog/create-edit-permission-dialog.component';
import { DeletePermissionDialogComponent } from './dialogs/delete-permission-dialog/delete-permission-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GuidanceComponent } from './components/guidance/guidance.component';
import { TreeWithCheckboxesComponent } from './components/tree-with-checkboxes/tree-with-checkboxes.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    ListManagementComponent,
    SearchComponent,
    TableTemplateComponent,
    DetailListComponent,
    TransferListComponent,
    ChipSelectComponent,
    RowByRowComponent,
    RoleEditComponent,
    PageNotFoundComponent,
    TransferDialogComponent,
    CreateEditRoleDialogComponent,
    CreateEditDialogComponent,
    DeletePermissionDialogComponent,
    GuidanceComponent,
    TreeWithCheckboxesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatTableModule,
    MatInputModule,
    MatButtonModule,
    FlexLayoutModule,
    MatPaginatorModule,
    MatListModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatGridListModule,
    MatDialogModule,
    MatCardModule,
    RouterModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatTreeModule,
    MatCheckboxModule,
    MatProgressSpinnerModule
  ],
  exports: [
    CommonModule,
    ListManagementComponent,
    SearchComponent,
    TableTemplateComponent,
    DetailListComponent,
    TransferListComponent,
    ChipSelectComponent,
    RowByRowComponent,
    RoleEditComponent,
    PageNotFoundComponent,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatButtonModule,
    FlexLayoutModule,
    GuidanceComponent,
    TreeWithCheckboxesComponent,
    MatProgressSpinnerModule
  ],
  providers: []
})
export class SharedModule { }
